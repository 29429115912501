import styled from "styled-components";
import { Form, Input, InputNumber, Select, DatePicker, Button } from "antd";

const Wrapper = styled(Form)`
  width: 100%;
  overflow: auto;
`;

const Header = styled.div`
  background-color: #2d378c;
  min-height: 10rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .title {
    margin: 1.5rem 0 0;

    span {
      color: #fff;
    }
  }

  .upload-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0;
  color: #2d378c;
  font-weight: 700;
  font-size: 0.9rem;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &.icon {
      color: #2d378c;
    }
  }
  span {
    color: #2d378c;
  }
`;

const SectionContentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 1.5rem;
`;

const FormItem = styled(Form.Item)`
  margin-top: -25px;
  width: 100%;

  input {
    height: 4rem;
    padding: 0 15px;
  }

  .ant-form-item-label {
    top: 25px;
    padding-left: 13px;
    font-size: 14px;
    z-index: 1;
  }

  .ant-form-item-label > label {
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    background-color: white;
    padding: 0px 10px;
  }

  @media only screen and (min-width: 766px) {
    display: block;

    .ant-form-item-label {
      top: 17.5px;
      font-size: 14px;
      z-index: 1;
    }

    .ant-form-item-label > label {
      position: relative;
      display: inline-flex;
      align-items: center;
      max-width: 100%;
      height: 32px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
    }
  }
`;

const InputTextFields = styled(Input)`
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;

  .ant-input {
    position: relative;
    padding: 8px 12px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;

    &:hover {
      border-color: #1890ff;
    }

    &:focus {
      border-color: #1890ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    &.ant-input-disabled {
      background-color: #f5f5f5;
      color: rgba(0, 0, 0, 0.65);
      cursor: not-allowed;
      border-color: #d9d9d9;
    }
  }
`;

const InputPhoneNumberField = styled(InputNumber)`
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
`;

const InputNumberField = styled(InputNumber)`
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
`;

const InputSelectField = styled(Select)`
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
  padding: 10px 0px;

  .ant-select-selector {
    border: none !important;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border: none !important;
  }
`;

/** PersonalInformation.js **/
const BirthdateSelectContainer = styled.div`
  display: flex;
  gap: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
  padding: 10px;

  .ant-row {
    margin-top: -1.5rem;
  }

  @media only screen and (min-width: 768px) {
    width: 100%;
    justify-content: space-between;

    .personal-info-date-select {
      margin-top: 0;
    }
  }
`;

/** PersonalInformation.js **/
const InputDateSelectField = styled(Select)`
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
  padding: 10px 0px;

  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    z-index: 1;
  }
`;

const InputDateField = styled(DatePicker)`
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
  padding: 10px;
`;

const UploadImageAvatar = styled.img`
  border-radius: 50%;
  min-height: 130px;
  max-height: 130px;
  object-fit: cover;
  min-width: 130px;
  max-width: 130px;
`;

const ReuploadBorder = styled(Button)`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  border-radius: 20px;
  width: 100%;
  height: 100%;

  span {
    color: #000 !important;
  }
`;

export {
  Wrapper,
  Header,
  SectionHeader,
  SectionContentItem,
  FormItem,
  Input,
  InputDateField,
  InputDateSelectField,
  InputNumberField,
  InputSelectField,
  InputTextFields,
  InputPhoneNumberField,
  BirthdateSelectContainer,
  UploadImageAvatar,
  ReuploadBorder,
};
