import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import NewCitizenContextProvider from "../src/pages/Register/Form/NewCitizenContext";
import SocialServiceProvider from "pages/SocialService/Provider";
import QrScannerProvider from "pages/QRScan/Provider";
import SearchProvider from "pages/Search/SearchContext";
import CitizenEditProvider from "pages/Edit/Form/CitizenEditContext";
import AuthProvider from "auth/Provider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <NewCitizenContextProvider>
          <CitizenEditProvider>
            <SocialServiceProvider>
              <QrScannerProvider>
                <SearchProvider>
                  <App />
                </SearchProvider>
              </QrScannerProvider>
            </SocialServiceProvider>
          </CitizenEditProvider>
        </NewCitizenContextProvider>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>,
);
