import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, Select } from "antd";
import { getAgencies, getAgencyPrograms } from "services/meta";
import { useNavigate } from "react-router-dom";
import { getProgramTypes, redeemSocialService } from "services/citizen";
import { useSocialServiceContext } from "../Context";
import TextArea from "antd/lib/input/TextArea";
import StyledButton from "components/button/Button";
import styled from "styled-components";
import { useQrScannerContext } from "pages/QRScan/Context";

const RedeemButton = styled(StyledButton)`
  background: #293392;
`;

const RedeemForm = () => {
  const navigate = useNavigate();
  const [agency, setAgency] = useState([]);
  const [agencyPrograms, setAgencyPrograms] = useState([]);
  const [programTypes, setProgramTypes] = useState([]);
  const { citizen } = useSocialServiceContext();
  const { user: agencyUser } = useQrScannerContext();

  const [form] = Form.useForm();

  const [selectedAgency, setSelectedAgency] = useState();
  const [selectedProgram, setSelectedProgram] = useState();
  const [selectedProgramType, setSelectedProgramType] = useState();

  const [disabled, setDisabled] = useState({
    agency: false,
    program: false,
    program_type: false,
  });

  console.log({
    selectedAgency,
    selectedProgram,
  });

  const onFinish = ({ proposed_amount, agency, program, program_type, ...rest }) => {
    redeemSocialService({
      citizen: citizen.id,
      proposed_amount: proposed_amount || 0,
      agency,
      program,
      program_type,
      overrides: {
        agency: rest["overrides.agency"],
        program: rest["overrides.program"],
        details: rest["overrides.details"],
      },
      agency_user: agencyUser?.id || null,
    })
      .then(() => {
        navigate(-1);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    getAgencies().then(agency => setAgency(agency?.results.map(({ name, id }) => ({ value: id, label: name }))));
  }, []);

  useEffect(() => {
    const disabledFields = {
      agency: false,
      program: false,
      program_type: false,
    };

    if (selectedAgency) {
      getAgencyPrograms({ id: selectedAgency })
        .then(programs => {
          const agencyPrograms = programs?.results || [];

          setAgencyPrograms(agencyPrograms.map(({ name, id }) => ({ value: id, label: name })));

          // sets the only program for agency if only have one program
          if (agencyPrograms && agencyPrograms.length < 3) {
            const program = agencyPrograms.filter(({ id }) => id !== 110)[0].id;
            form.setFieldsValue({
              program,
            });

            setSelectedProgram(program);

            disabledFields.program = true;
          }
        })
        .then(() => {
          getProgramTypes().then(programTypes => {
            setProgramTypes(programTypes?.results.map(({ name, id }) => ({ value: id, label: name })));

            // special case for bpsf
            if (selectedAgency === 34) {
              form.setFieldsValue({
                program_type: 3,
              });

              setSelectedProgramType(3);
              disabledFields.program_type = true;
            }
          });
        });
      setDisabled(disabledFields);
    }
  }, [selectedAgency]);

  useEffect(() => {
    const agencyId = agencyUser.agency?.id;
    form.setFieldsValue({ agency: agencyId });
    setSelectedAgency(agencyId);
  }, [agencyUser]);

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 24,
      }}
      style={{
        minWidth: "90%",
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      <Form.Item
        name="agency"
        wrapperCol={{
          span: 24,
        }}
        rules={[
          {
            required: true,
            message: "Agency is required",
          },
        ]}>
        <Select
          showSearch
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
          onChange={value => {
            setSelectedAgency(value);

            form.setFieldsValue({ program: undefined });
            setSelectedProgram(undefined);
          }}
          options={agency}
          placeholder={"Select Agency"}
          disabled={disabled.agency}
        />
      </Form.Item>

      {selectedAgency === 33 ? (
        <Form.Item
          wrapperCol={{
            span: 24,
          }}
          name="overrides.agency"
          rules={[
            {
              required: true,
              message: "Agency Name is required",
            },
          ]}>
          <Input style={{ width: "100%" }} placeholder={"Enter Agency Name"} />
        </Form.Item>
      ) : null}

      <Form.Item
        wrapperCol={{
          span: 24,
        }}
        name="program"
        dependencies={["agency"]}
        rules={[
          {
            required: true,
            message: "Program is required",
          },
        ]}>
        <Select
          showSearch
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
          value={selectedProgram}
          onChange={value => {
            setSelectedProgram(value);
          }}
          options={agencyPrograms}
          placeholder={"Select Program"}
          disabled={disabled.program}
        />
      </Form.Item>

      {selectedProgram === 110 ? (
        <Form.Item
          wrapperCol={{
            span: 24,
          }}
          name="overrides.program"
          dependencies={["agency", "overrides.agency"]}
          rules={[
            {
              required: true,
              message: "Program Name is required",
            },
          ]}>
          <Input style={{ width: "100%" }} placeholder={"Enter Social Service"} />
        </Form.Item>
      ) : null}

      <Form.Item
        wrapperCol={{
          span: 24,
        }}
        name="program_type"
        dependencies={["agency"]}
        rules={[
          {
            required: true,
            message: "Program Type is required",
          },
        ]}>
        <Select
          showSearch
          value={selectedProgramType}
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
          onChange={value => {
            setSelectedProgramType(value);
          }}
          options={programTypes}
          placeholder={"Select Program Type"}
          disabled={disabled.program_type}
        />
      </Form.Item>

      {selectedProgramType === 1 ? (
        <Form.Item
          wrapperCol={{
            span: 24,
          }}
          name="proposed_amount"
          dependencies={["agency", "program"]}
          rules={[
            {
              required: true,
              message: "Proposed Amount is required",
            },
          ]}>
          <InputNumber
            prefix="₱"
            style={{ width: "100%" }}
            disabled={!selectedProgram}
            placeholder={"Input Proposed Amount"}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          />
        </Form.Item>
      ) : null}

      {selectedProgramType === 2 ? (
        <Form.Item
          wrapperCol={{
            span: 24,
          }}
          name="overrides.details"
          dependencies={["agency", "program"]}
          rules={[
            {
              required: true,
              message: "Details is required",
            },
          ]}>
          <TextArea placeholder="Enter Details" autoSize />
        </Form.Item>
      ) : null}

      <Form.Item>
        <RedeemButton type="primary" htmlType="submit">
          Redeem
        </RedeemButton>
      </Form.Item>
    </Form>
  );
};

export default RedeemForm;
