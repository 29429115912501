import React, { useState, useContext } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { RegisterHomePageContainer } from "../Register/components/Register.styled";
import BP_LOGO from "../../assets/bagong_pilipinas_logo.png";
import BG from "../../assets/bg.png";
import GRADIENT from "../../assets/corner_gradient.png";
import MOBILE_BG from "../../assets/mobile_bg.png";
import MOBILE_GRADIENT from "../../assets/mobile_gradient.png";
import { Form, Alert, Input } from "antd";
import StyledButton from "components/button/Button";
import { searchLoginService } from "services/citizen";
import { useAuth } from "auth/Context";

const Login = () => {
  const { dispatch } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const redirectPath = new URLSearchParams(location.search).get("redirect") || "/search";

  const onFinish = async values => {
    try {
      const response = await searchLoginService(values.username, values.password);
      localStorage.setItem("access", response.access);
      localStorage.setItem("refresh", response.refresh);
      dispatch({
        type: "LOGIN",
        user: null,
        tokens: {
          access: response.access,
          refresh: response.refresh,
        },
      });
      navigate(redirectPath, { replace: true });
    } catch (error) {
      setError(error?.detail || "User not found");
      console.warn("error", error);
    }
  };

  const onFinishFailed = errorInfo => {
    console.error("Failed:", errorInfo);
  };

  return (
    <RegisterHomePageContainer>
      <div className="left-side-container">
        <img src={BP_LOGO} className="bp-logo" />
        <img src={BG} className="bg" />
      </div>

      <div className="right-side-container" style={{ justifyContent: "center" }}>
        <div style={{ width: "100%" }}>
          <Form
            form={form}
            name="basic"
            layout="vertical"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              minWidth: "90%",
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Form.Item
              name="username"
              label={<label style={{ color: "#ffffff" }}>Username</label>}
              rules={[
                {
                  required: true,
                  message: "Username is required",
                },
              ]}>
              <Input
                style={{ color: "#000000", fontSize: "16px" }}
                name="username"
                onChange={e =>
                  form.setFieldsValue(values => ({
                    ...values,
                    username: e.target.value,
                  }))
                }
              />
            </Form.Item>

            <Form.Item
              style={{ color: "#000000" }}
              wrapperCol={{
                span: 24,
              }}
              name="password"
              label={<label style={{ color: "#ffffff" }}>Password</label>}
              type="password"
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
              ]}>
              <Input.Password
                style={{ color: "#000000", fontSize: "16px" }}
                name="password"
                onChange={e =>
                  form.setFieldsValue(values => ({
                    ...values,
                    password: e.target.value,
                  }))
                }
              />
            </Form.Item>

            {error && (
              <Form.Item>
                <Alert description={error} type="error" closable onClose={() => setError("")} />
              </Form.Item>
            )}

            <Form.Item>
              <StyledButton type="primary" htmlType="submit" block>
                Login
              </StyledButton>
            </Form.Item>
          </Form>
        </div>
        <img src={GRADIENT} className="gradient" />
      </div>

      <img src={MOBILE_GRADIENT} className="mobile-gradient" />
      <img src={MOBILE_BG} className="mobile-bg" />
    </RegisterHomePageContainer>
  );
};

export default Login;
