/* eslint-disable no-unused-vars */
import {
  SummaryHeader,
  SummarySection,
  SummarySectionContent,
  SummarySectionHeader,
  SummarySectionContentEditable,
  UploadImageAvatar,
  SummarySectionContentItem,
} from "pages/Register/components/Register.styled";
import React, { useState, useContext, useEffect } from "react";
import { NewCitizenContext } from "../NewCitizenContext";
import {
  UserOutlined,
  EditOutlined,
  PushpinOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  FormItem,
  InputTextFields,
  InputSelectField,
  InputPhoneNumberField,
  BirthdateSelectContainer,
  InputDateSelectField,
} from "../../components/InputField.styled";
import { daysOptions, monthsOptions, yearOptions } from "../../../../constant/selectConstants";
import { Form } from "antd";
import { addBarangay } from "services/public_registration.service";
import moment from "moment";
import allProvince from "../../../../jsonLib/allProvince.json";
import allMunicipalities from "../../../../jsonLib/allMunicipalities.json";
import { validators } from "utils/validators";

const Summary = ({ form }) => {
  const [forms] = Form.useForm();

  const [showAddBarangay, setShowAddBarangay] = useState(false);
  const [editPersonalInfo, setEditPersonalInfo] = useState(false);
  const [editPersonalAddress, setEditPersonalAddress] = useState(false);
  const {
    state,
    genderOptions,
    stateDispatchMain,
    formatPhoneNumber,
    parsePhoneNumber,
    provinceOptions,
    municipalityOptions,
    barangayOption,
    setSelectedProvince,
    setSelectedMunicipality,
    selectedMunicipality,
    selectedProvince,
    isLoading,
  } = useContext(NewCitizenContext);

  useEffect(() => {
    const year = form?.getFieldValue("year");
    const month = form?.getFieldValue("month");
    const date = form?.getFieldValue("date");

    if (date) {
      const dateOptions = daysOptions(month, year);

      if (!dateOptions.find(d => d.value === date)) {
        form?.setFieldsValue({ date: undefined });
      }
    }
  }, [form?.getFieldValue("month"), form?.getFieldValue("year")]);

  return (
    <div>
      <SummaryHeader>
        <div style={{ margin: "30px 0" }}>
          <UploadImageAvatar src={state.citizenDetails.profile} />
          <div className="title">
            <span>{`${state.citizenDetails.last_name}, ${state.citizenDetails.first_name}`}</span>
          </div>
        </div>
      </SummaryHeader>

      <SummarySection>
        <SummarySectionHeader>
          <div>
            <UserOutlined className="icon" color="#2d378c" />
            <span>Personal Information</span>
          </div>
          {!editPersonalInfo ? (
            <EditOutlined color="#2d378c" className="icon edit" onClick={() => setEditPersonalInfo(true)} />
          ) : (
            <SaveOutlined color="#2d378c" className="icon edit" onClick={() => setEditPersonalInfo(false)} />
          )}
        </SummarySectionHeader>
        {!editPersonalInfo ? (
          <SummarySectionContent>
            <SummarySectionContentItem>
              <span>Pangalan</span>
              <span>{state.citizenDetails.first_name}</span>
            </SummarySectionContentItem>
            <SummarySectionContentItem>
              <span>Apilyedo</span>
              <span>{state.citizenDetails.last_name}</span>
            </SummarySectionContentItem>
            <SummarySectionContentItem>
              <span>Ext (Sr, Jr, I, II, III)</span>
              <span>{state.citizenDetails.name_extension}</span>
            </SummarySectionContentItem>
            <SummarySectionContentItem>
              <span>Kasarian/Sex</span>
              <span>{genderOptions.find(gender => gender.value === state.citizenDetails.gender)?.label}</span>
            </SummarySectionContentItem>
            <SummarySectionContentItem>
              <span>Birthdate</span>
              <span>
                {moment(
                  `${state.citizenDetails.birthdate_year}-${state.citizenDetails.birthdate_month}-${state.citizenDetails.birthdate_day}`,
                  "YYYY-MM-DD",
                  false,
                ).format("MMM DD, YYYY")}
              </span>
            </SummarySectionContentItem>
            <SummarySectionContentItem>
              <span>Mobile Number</span>
              <span>{state.citizenDetails.phone_number}</span>
            </SummarySectionContentItem>
            <SummarySectionContentItem>
              <span>Facebook Profile Link</span>
              <span>{state.citizenDetails.fb_profile_link}</span>
            </SummarySectionContentItem>
          </SummarySectionContent>
        ) : (
          <SummarySectionContentEditable>
            <FormItem
              label="Pangalan"
              name="first_name"
              rules={[{ required: true, message: "First name is required" }, validators.name]}>
              <InputTextFields
                name="first_name"
                value={state.citizenDetails.first_name}
                onChange={e =>
                  stateDispatchMain({
                    key: "citizenDetails",
                    payload: {
                      ...state.citizenDetails,
                      first_name: e.target.value,
                    },
                  })
                }
              />
            </FormItem>
            <FormItem
              label="Apelyido"
              name="last_name"
              rules={[{ required: true, message: "Last name is required" }, validators.name]}>
              <InputTextFields
                name="last_name"
                value={state.citizenDetails.last_name}
                onChange={e =>
                  stateDispatchMain({
                    key: "citizenDetails",
                    payload: {
                      ...state.citizenDetails,
                      last_name: e.target.value,
                    },
                  })
                }
              />
            </FormItem>
            <FormItem
              label="Ext (Sr,Jr,I,II,III)"
              name="suffix"
              rules={[
                validators.name,
                {
                  max: 10,
                  message: "Name extension cannot be longer than 10 characters",
                },
              ]}>
              <InputTextFields
                name="name_extension"
                value={state.citizenDetails.suffix}
                onChange={e =>
                  stateDispatchMain({
                    key: "citizenDetails",
                    payload: {
                      ...state.citizenDetails,
                      name_extension: e.target.value,
                    },
                  })
                }
              />
            </FormItem>
            <FormItem label="Kasarian/Sex" name="gender" rules={[{ required: true, message: "Gender is required" }]}>
              <InputSelectField
                showSearch
                name="gender"
                bordered={false}
                optionFilterProp="children"
                onChange={value => {
                  stateDispatchMain({
                    key: "citizenDetails",
                    payload: {
                      ...state.citizenDetails,
                      gender: value,
                    },
                  });
                }}
                options={genderOptions}
                filterOption={(input, option) => {
                  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              />
            </FormItem>
            <FormItem label="Birthdate">
              <BirthdateSelectContainer>
                <FormItem
                  className="personal-info-date-select"
                  label="Year"
                  name="year"
                  rules={[{ required: true, message: "Year is required." }]}>
                  <InputDateSelectField
                    options={yearOptions()}
                    showSearch
                    allowClear
                    onChange={value => {
                      stateDispatchMain({
                        key: "citizenDetails",
                        payload: {
                          ...state.citizenDetails,
                          birthdate_year: value,
                        },
                      });
                    }}
                  />
                </FormItem>
                <FormItem
                  className="personal-info-date-select"
                  label="Month"
                  name="month"
                  rules={[{ required: true, message: "Month is required." }]}>
                  <InputDateSelectField
                    options={monthsOptions()}
                    filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                    showSearch
                    allowClear
                    onChange={value => {
                      stateDispatchMain({
                        key: "citizenDetails",
                        payload: {
                          ...state.citizenDetails,
                          birthdate_month: value,
                        },
                      });
                    }}
                  />
                </FormItem>
                <FormItem
                  className="personal-info-date-select"
                  label="Date"
                  name="date"
                  rules={[{ required: true, message: "Date is required." }]}>
                  <InputDateSelectField
                    options={daysOptions(form?.getFieldValue("month"), form?.getFieldValue("year"))}
                    disabled={!form?.getFieldValue("year") || !form?.getFieldValue("month")}
                    showSearch
                    allowClear
                    onChange={value => {
                      stateDispatchMain({
                        key: "citizenDetails",
                        payload: {
                          ...state.citizenDetails,
                          birthdate_day: value,
                        },
                      });
                    }}
                  />
                </FormItem>
              </BirthdateSelectContainer>
            </FormItem>
            <FormItem
              label="Mobile Number"
              name="phone_number"
              validateTrigger={["onBlur"]}
              rules={[
                { required: true, message: "Mobile number is required" },
                {
                  validator: async (_, value) => {
                    if (value.toString().length < 10) {
                      return Promise.reject("Incorrect mobile number format");
                    } else {
                      return Promise.resolve();
                    }
                  },
                  message: "Incorrect mobile number format",
                },
              ]}>
              <InputPhoneNumberField
                name="phone_number"
                autoComplete="off"
                formatter={formatPhoneNumber}
                parser={parsePhoneNumber}
                onChange={value => {
                  stateDispatchMain({
                    key: "citizenDetails",
                    payload: {
                      ...state.citizenDetails,
                      phone_number: value,
                    },
                  });
                }}
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                defaultValue={63}
                maxLength={12}
                minLength={12}
              />
            </FormItem>

            <FormItem
              label="Facebook Profile Link"
              name="fb_profile_link"
              rules={[
                {
                  validator: async (_, value) => {
                    const pattern =
                      /^(https?:\/\/)?(www\.)?(facebook\.com|fb\.com)\/[^\s?#]+(?:\?[^\s#]*)?(?:#[^\s]*)?$/;
                    if (!value || pattern.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Invalid facebook profile link");
                    }
                  },
                  message: "Invalid facebook profile link",
                },
              ]}
              extra="https://facebook.com/juan.delacruz">
              <InputTextFields
                name="fb_profile_link"
                value={state.citizenDetails.fb_profile_link}
                onChange={e => {
                  if (e.target.value.length > 0) {
                    stateDispatchMain({
                      key: "citizenDetails",
                      payload: {
                        ...state.citizenDetails,
                        fb_profile_link: e.target.value,
                      },
                    });
                  } else {
                    stateDispatchMain({
                      key: "citizenDetails",
                      payload: {
                        ...state.citizenDetails,
                        fb_profile_link: null,
                      },
                    });
                  }
                }}
              />
            </FormItem>
          </SummarySectionContentEditable>
        )}
      </SummarySection>
      <hr />
      <SummarySection>
        <SummarySectionHeader>
          <div>
            <PushpinOutlined color="#2d378c" className="icon" />
            <span>Address</span>
          </div>
          {!editPersonalAddress ? (
            <EditOutlined color="#2d378c" className="icon edit" onClick={() => setEditPersonalAddress(true)} />
          ) : (
            <SaveOutlined color="#2d378c" className="icon edit" onClick={() => setEditPersonalAddress(false)} />
          )}
        </SummarySectionHeader>
        {!editPersonalAddress ? (
          <SummarySectionContent>
            <SummarySectionContentItem>
              <span>Province</span>
              <span>{allProvince.find(province => province.id === state.citizenDetails.province)?.name}</span>
            </SummarySectionContentItem>
            <SummarySectionContentItem>
              <span>City</span>
              <span>
                {allMunicipalities.find(municipality => municipality.id === state.citizenDetails.municipality)?.name}
              </span>
            </SummarySectionContentItem>
            <SummarySectionContentItem>
              <span>Barangay</span>
              <span>
                {barangayOption?.find(barangay => barangay?.id === state.citizenDetails.barangay)?.name ||
                  state.citizenDetails?.barangay_name}
              </span>
            </SummarySectionContentItem>
            <SummarySectionContentItem>
              <span>House No./Street/Purok</span>
              <span>{state.citizenDetails.address}</span>
            </SummarySectionContentItem>
          </SummarySectionContent>
        ) : (
          <SummarySectionContentEditable>
            <FormItem label="Probinsya" name="province" rules={[{ required: true, message: "Probinsya is required" }]}>
              <InputSelectField
                name="province"
                bordered={false}
                onChange={options => {
                  stateDispatchMain({
                    key: "citizenDetails",
                    payload: {
                      ...state.citizenDetails,
                      province: options,
                    },
                  });
                  setSelectedProvince(options);
                }}
                options={provinceOptions}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              />
            </FormItem>
            <FormItem
              label="Lungsod/Munisipyo"
              name="municipality"
              rules={[{ required: true, message: "Lungsod/Munisipyo is required" }]}>
              <InputSelectField
                key="municipality"
                name="municipality"
                bordered={false}
                onChange={(_, option) => {
                  stateDispatchMain({
                    key: "citizenDetails",
                    payload: {
                      ...state.citizenDetails,
                      municipality: option?.value,
                    },
                  });
                  setSelectedMunicipality(option?.value);
                  form.setFieldsValue({ municipality: option });
                }}
                disabled={!selectedProvince}
                options={municipalityOptions}
                value={selectedMunicipality || null}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              />
            </FormItem>
            <div style={{ display: "flex" }}>
              <FormItem label="Barangay" name="barangay" style={{ marginRight: "1rem", width: "70%" }}>
                {showAddBarangay ? (
                  <InputTextFields
                    disabled={!selectedMunicipality}
                    name="barangay"
                    onChange={e =>
                      stateDispatchMain({
                        key: "citizenDetails",
                        payload: {
                          ...state.citizenDetails,
                          barangay: e.target.value,
                        },
                      })
                    }
                    onBlur={async e => {
                      if (e.target.value) {
                        const res = await addBarangay(e.target.value, selectedMunicipality);
                        stateDispatchMain({
                          key: "citizenDetails",
                          payload: {
                            ...state.citizenDetails,
                            barangay: res.id,
                            barangay_name: res.name,
                          },
                        });
                      }
                    }}
                  />
                ) : (
                  <InputSelectField
                    name="barangay"
                    allowClear
                    bordered={false}
                    onChange={(_, option) => {
                      stateDispatchMain({
                        key: "citizenDetails",
                        payload: {
                          ...state.citizenDetails,
                          barangay: option?.value,
                          barangay_name: option?.name,
                        },
                      });
                      form.setFieldsValue({ barangay: option });
                    }}
                    options={barangayOption}
                    disabled={!selectedMunicipality}
                    showSearch
                    notFoundContent={!isLoading ? "Loading.." : "No Data"}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                  />
                )}
              </FormItem>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "30%",
                  textAlign: "center",
                }}>
                <div style={{ margin: "10px 0" }}>
                  {showAddBarangay ? (
                    <a>
                      <CloseCircleOutlined
                        style={{ transform: "scale(1.5)" }}
                        onClick={() => setShowAddBarangay(curr => !curr)}
                      />
                    </a>
                  ) : (
                    <a>
                      <PlusCircleOutlined
                        style={{ transform: "scale(1.5)" }}
                        onClick={() => setShowAddBarangay(curr => !curr)}
                      />
                    </a>
                  )}
                </div>
                <div>
                  {showAddBarangay ? (
                    <p>Go back to list</p>
                  ) : (
                    <>
                      <p>Add New Barangay</p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <FormItem
              label="House No./Purok"
              name="address"
              rules={[{ required: true, message: "Address is required" }, validators.address]}>
              <InputTextFields
                name="address"
                onChange={e =>
                  stateDispatchMain({
                    key: "citizenDetails",
                    payload: {
                      ...state.citizenDetails,
                      address: e.target.value,
                    },
                  })
                }
              />
            </FormItem>
          </SummarySectionContentEditable>
        )}
      </SummarySection>
    </div>
  );
};

export default Summary;
