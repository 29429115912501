import { ConfirmContainer } from "pages/Edit/components/Form.styled";
import React, { useContext } from "react";
import { Form, Checkbox } from "antd";
import { CitizenEditContext } from "../CitizenEditContext";

const CitizenEditConfirm = () => {
  const { agreement, setAgreement } = useContext(CitizenEditContext);
  return (
    <ConfirmContainer>
      <h3 className="header-title">Are you sure you want to submit your edit?</h3>
      <div className="checkbox-container">
        <Form.Item>
          <Checkbox name="agreement" checked={agreement} onChange={e => setAgreement(e.target.checked)} />
        </Form.Item>
        <p className="agreement-text">Yes, I Agree that all information I have edited are true and updated.</p>
      </div>
    </ConfirmContainer>
  );
};

export default CitizenEditConfirm;
