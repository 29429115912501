import React from "react";
import styled from "styled-components";
import { Card } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

// Styled Components

const StyledCard = styled(Card)`
  display: flex;
  flex: 1 1 200px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 5px;
  border-radius: 8px;
  transition: 0.3s;
  min-height: 100px;
  background: #fbfcfe;
  max-width: 194px;
  max-height: 244px;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  .ant-card-body {
    padding: 20px;
  }
`;

const IconWrapper = styled.div`
  margin-bottom: 16px;
`;

const CardTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 4px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
`;

const CardDescription = styled.div`
  font-size: 0.9rem;
  color: #777;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
`;

const TopCard = ({ iconType, iconSrc, title, description }) => {
  const IconComponent = iconSrc
    ? () => <img src={iconSrc} style={{ width: "3rem", height: "3rem" }} alt="Icon" />
    : iconType === "info"
    ? InfoCircleOutlined
    : InfoCircleOutlined;

  return (
    <StyledCard>
      <IconWrapper>
        <IconComponent />
      </IconWrapper>
      <CardTitle>{description}</CardTitle>
      <CardDescription>{title}</CardDescription>
    </StyledCard>
  );
};

export default TopCard;
