export const theme = {
  colors: {
    text: {
      base: "#111111",
      primary: "rgb(45,55,140)",
      pink: "rgb(45,55,140)",
      gray: "#979797",
      white: "white",
    },
    bg: {
      input: "#F1F1F1",
    },
  },
};
