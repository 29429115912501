import styled from "styled-components";

const LayoutContainer = styled.div`
  width: 100%;
  height: 100%;

  @media only screen and (min-width: 768px) {
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const LayoutLogoContainer = styled.div`
  width: 100%;
  height: 145px;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const Logo = styled.img`
  width: 5rem;
  height: auto;
  margin-top: 2.5rem;
  margin-left: 20px;

  @media only screen and (min-width: 768px) {
    width: 8rem;
    margin: 0 auto;
    display: flex;
    margin-top: 5rem;
  }
`;

export { LayoutContainer, LayoutLogoContainer, Logo };
