import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BP_LOGO from "assets/bagong_pilipinas_logo.png";
import { Button, Form, Input, Select, Statistic, message, Divider } from "antd";
import { daysOptions, monthsOptions, yearOptions } from "constant/selectConstants";
import moment from "moment";
import TopCard from "./components/TopCard";
import {
  getAgencies,
  getLocations,
  getPrograms,
  getSortBy,
  getOverallMetricsRegistrants,
  getOverallMetricsAvailedServices,
  getLocationMetrics,
  getAvailedServiceMetrics,
} from "services/statistic.service";
import StatisticTable from "./components/StatisticTable";
import { useAuth } from "auth/Context";
import { useNavigate } from "react-router-dom";
import total_registrants_icon from "assets/total_registrants_icon.png";
import registration_icon from "assets/registration_icon.png";
import municipality_icon from "assets/municipality_icon.png";
import province_icon from "assets/province_icon.png";
import qr_scan_icon from "assets/qr_scan_icon.png";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1rem;

  .search-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .ant-row {
      margin: 0;
    }

    @media only screen and (min-width: 768px) {
      width: 65vw;
      margin: 0 auto;
    }

    @media only screen and (min-width: 900px) {
      width: 50vw;
      margin: 0 auto;
    }
  }

  .filter-container {
    gap: 0.5rem;
    margin: 0;

    @media only screen and (min-width: 768px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .filter-select {
      margin-top: 0.5rem;
      width: 100%;
    }
  }

  .search-btn {
    border-radius: 8px;
    width: 100%;

    &.ant-btn-primary {
      background-color: #ac141e;
      color: #ffffff;
      border-color: #ac141e;

      span {
        color: #ffffff;
      }

      &:hover,
      &:focus {
        background-color: #9a121b;
        border-color: #9a121b;
      }
    }
  }

  .bp-logo {
    width: 5rem;
    margin: 0 auto;
    z-index: 5;

    @media only screen and (min-width: 390px) {
      width: 10rem;
    }

    @media only screen and (min-width: 480px) and (orientation: portrait) {
      width: 8.5rem;
    }

    @media only screen and (min-width: 768px) {
      width: 12.5rem;
      margin: 0 auto;
      margin-top: 2rem;
    }
  }

  .bg {
    position: fixed;
    width: 55vw;
    bottom: 0;

    @media only screen and (max-width: 899px) {
      display: none;
    }
  }
`;
const FormContainer = styled.div`
  .search-by-select {
    flex-direction: column;
  }
  @media only screen and (min-width: 768px) {
    width: 65vw;
    margin: 0 auto;
    .search-by-select {
      flex-direction: row;
    }
  }

  @media only screen and (min-width: 900px) {
    width: 50vw;
    margin: 0 auto;
  }
`;

const LogoutButton = styled(Button)`
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 12px;
`;

const TotalRegistrants = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .total {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .backlink {
    color: #007bff;
    text-decoration: underline;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

const CardContainer = styled.div`
  display: flex;
  align-items: stretch;
  overflow-x: auto;
  white-space: nowrap;
  flex-direction: row;
  justify-content: space-around;

  @media only screen and (min-width: 768px) {
    scrollbar-width: 12px;
    ::-webkit-scrollbar {
      width: 1em;
      height: 1em;
    }
  }
`;
function StatisticPage() {
  const programOptions = [
    {
      label: "BPSF",
      value: "1",
    },
  ];

  const sortByOptions = [
    {
      label: "Number of Registrants",
      value: "1",
    },
    {
      label: "Number of Availed Services",
      value: "2",
    },
  ];

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedSortBy, setSelectedSortBy] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedAgencyBreakdown, setSelectedAgencyBreakdown] = useState(null);
  const [agencyBreakdownOptions, setAgencyBreakdownOptions] = useState([]);
  // const [sortByOptions, setSortByOptions] = useState([]);
  const [totalRegistrant, setTotalRegistrant] = useState(0);
  const [overallMetrics, setOverallMetrics] = useState([]);

  const { logout } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [agenciesResponse, locationsResponse] = await Promise.all([getAgencies(), getLocations()]);

        const formattedLocations = locationsResponse?.results?.map(location => ({
          label: location.name,
          value: location.id,
        }));

        const formattedAgencies = agenciesResponse?.results?.map(agency => ({
          label: agency.name,
          value: agency.id,
        }));

        const locationOptionsHolder = [{ label: "All Provinces", value: "ALL" }, ...formattedLocations];
        const agencyOptionHolder = [{ label: "All Agencies", value: "ALL" }, ...formattedAgencies];

        setLocationOptions(locationOptionsHolder);
        setAgencyBreakdownOptions(agencyOptionHolder);

        if (formattedLocations.length > 0) {
          const defaultProgram = programOptions[0].value;
          const defaultLocation = locationOptionsHolder[0].value;

          form.setFieldsValue({
            program: defaultProgram,
            location: defaultLocation,
            sort_by: sortByOptions[0].value,
          });

          setSelectedProgram(defaultProgram);
          setSelectedLocation(defaultLocation);
          setSelectedSortBy(sortByOptions[0].value);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  // Card Metrics BPSF
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (selectedSortBy === "1" && selectedProgram === "1" && selectedLocation) {
          await getOverallMetricsRegistrants(selectedAgencyBreakdown, selectedLocation).then(data => {
            const cardData = [];
            if (data?.overall_metrics?.total_registrations) {
              cardData.push({
                title: "Total Registrants",
                description: formatNumber(data?.overall_metrics?.total_registrations.toString()),
                iconSrc: registration_icon,
              });
            }
            if (data?.overall_metrics?.top_province?.province_name) {
              cardData.push({
                title: "Top Province",
                description: data?.overall_metrics?.top_province.province_name,
                iconSrc: province_icon,
              });
            }
            if (data?.overall_metrics?.top_municipality?.municipality_name) {
              cardData.push({
                title: "Top Municipality",
                description: data?.overall_metrics?.top_municipality.municipality_name,
                iconSrc: municipality_icon,
              });
            }
            if (data?.overall_metrics?.highest_number_of_registrations_in_a_day) {
              cardData.push({
                title: "Highest Number of Registrations in a Day",
                description: formatNumber(
                  data?.overall_metrics?.highest_number_of_registrations_in_a_day?.registrants.toString(),
                ),
                iconSrc: total_registrants_icon,
              });
            }
            setOverallMetrics(cardData);
          });
        } else if (selectedSortBy === "2" && selectedProgram === "1" && selectedLocation && selectedAgencyBreakdown) {
          await getOverallMetricsAvailedServices(selectedAgencyBreakdown, selectedLocation).then(data => {
            const cardsData = [];
            if (data?.overall_metrics?.total_scanned_qr) {
              cardsData.push({
                title: "Total Scanned QR Codes",
                description: formatNumber(data?.overall_metrics?.total_scanned_qr.toString()),
                iconSrc: qr_scan_icon,
              });
            }
            if (data?.overall_metrics?.top_province?.province_name) {
              cardsData.push({
                title: "Top Province",
                description: data?.overall_metrics?.top_province.province_name,
                iconSrc: province_icon,
              });
            }
            if (data?.overall_metrics?.most_used_service?.program_name) {
              cardsData.push({
                title: "Top Availed Service",
                description: data?.overall_metrics?.most_used_service.program_name,
                iconSrc: total_registrants_icon,
              });
            }
            if (data?.overall_metrics?.top_agency?.agency_name) {
              cardsData.push({
                title: "Top Agency",
                description: data?.overall_metrics?.top_agency.agency_name,
                iconSrc: province_icon,
              });
            }
            if (data?.overall_metrics?.total_availed_services) {
              cardsData.push({
                title: "Total Availed Services",
                description: formatNumber(data?.overall_metrics?.total_availed_services.toString()),
                iconSrc: total_registrants_icon,
              });
            }
            if (data?.overall_metrics?.top_service?.program_name) {
              cardsData.push({
                title: "Top Service",
                description: data?.overall_metrics?.top_service.program_name,
                iconSrc: total_registrants_icon,
              });
            }
            setOverallMetrics(cardsData);
          });
        }
      } catch (error) {
        message.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedAgencyBreakdown, selectedLocation, selectedSortBy, selectedProgram]);

  const formatNumber = number => new Intl.NumberFormat("en-US").format(number);

  // Data Metrics
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (selectedSortBy === "1" && selectedProgram === "1" && selectedLocation) {
          await getLocationMetrics(selectedLocation).then(data => {
            if (selectedLocation !== "ALL") {
              const formattedData = data?.location_metrics_summary?.registrants_per_municipality?.map(locData => ({
                location_agency: locData?.municipality_name,
                count: locData?.registrants,
              }));
              const totalRegistrants = data?.location_metrics_summary?.registrants_per_municipality?.reduce(
                (sum, municipality) => sum + municipality.registrants,
                0,
              );
              setData(formattedData);
              setTotalRegistrant(formatNumber(totalRegistrants));
            } else {
              const formattedData = data?.location_metrics_summary?.registrants_per_province?.map(locData => ({
                location_agency: locData?.province_name,
                count: locData?.registrants,
              }));
              const totalRegistrants = data?.location_metrics_summary?.registrants_per_province?.reduce(
                (sum, province) => sum + province.registrants,
                0,
              );
              setTotalRegistrant(formatNumber(totalRegistrants) || 0);
              setData(formattedData);
            }
          });
          setSelectedAgencyBreakdown(null);
          form.setFieldsValue({
            agency_breakdown: null,
          });
        } else if (selectedSortBy === "2" && selectedProgram === "1" && selectedLocation && selectedAgencyBreakdown) {
          await getAvailedServiceMetrics(selectedAgencyBreakdown, selectedLocation).then(data => {
            const formattedData = data?.redeemed_services_summary?.map(locData => ({
              location_agency: locData?.agency_location_metrics,
              count: locData?.redeemed_services,
            }));
            const totalAvailedService = data?.redeemed_services_summary?.reduce(
              (sum, service) => sum + service.redeemed_services,
              0,
            );
            setTotalRegistrant(formatNumber(totalAvailedService) || 0);
            setData(formattedData);
          });
        }
      } catch (error) {
        message.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedAgencyBreakdown, selectedLocation, selectedSortBy, selectedProgram]);

  const columns = [
    {
      title: "Location/Agency",
      dataIndex: "location_agency",
      key: "location_agency",
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      render: text => formatNumber(text),
    },
  ];

  return (
    <Wrapper>
      <img src={BP_LOGO} className="bp-logo" />
      <LogoutButton
        onClick={() => {
          logout();
        }}
        size="small">
        Logout
      </LogoutButton>
      <FormContainer>
        <CardContainer>
          {overallMetrics.map((card, index) => {
            return <TopCard key={index} iconSrc={card.iconSrc} title={card.title} description={card.description} />;
          })}
        </CardContainer>
        <Form form={form} className="search-form" layout="vertical">
          <div className="filter-container">
            <Form.Item className="filter-select" label="Program" name="program">
              <Select
                options={programOptions}
                value={selectedProgram} // Controlled component
                filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                showSearch
                allowClear
                placeholder="Select Program"
                onChange={e => setSelectedProgram(e)}
              />
            </Form.Item>
            <Form.Item className="filter-select" label="Sort By" name="sort_by">
              <Select
                options={sortByOptions}
                value={selectedSortBy} // Controlled component
                filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                showSearch
                allowClear
                placeholder="Select Sort By"
                onChange={e => setSelectedSortBy(e)}
              />
            </Form.Item>
          </div>
          <div className="filter-container">
            <Form.Item className="filter-select" label="Locations" name="location">
              <Select
                options={locationOptions}
                value={selectedLocation} // Controlled component
                showSearch
                allowClear
                placeholder="Select Location"
                filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                onChange={e => setSelectedLocation(e)}
              />
            </Form.Item>
            <Form.Item className="filter-select" label="Agency Breakdown" name="agency_breakdown">
              <Select
                options={agencyBreakdownOptions}
                disabled={selectedSortBy === "1" || !selectedSortBy}
                showSearch
                allowClear
                placeholder="Select Agency Breakdown"
                filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                onChange={e => setSelectedAgencyBreakdown(e)}
              />
            </Form.Item>
          </div>
          <Divider />
          <TotalRegistrants>
            <span className="total">
              Total: {totalRegistrant}{" "}
              {selectedSortBy && (
                <span style={{ fontSize: "14px" }}> {selectedSortBy === "1" ? "Registrants" : "Availed Services"}</span>
              )}
            </span>
            <div className="backlink" onClick={() => navigate("/search")}>
              <span style={{ color: "#007bff", fontSize: 14 }}>Back to Search</span>
            </div>
          </TotalRegistrants>
          <StatisticTable
            data={data}
            loading={loading}
            pagination={{
              position: ["bottomRight"],
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              pageSize: pageSize,
              current: page,
              defaultCurrent: page,
              onChange: pg => setPage(pg),
              total,
            }}
            columns={columns}
            // resendInterval={resendInterval}
          />
        </Form>
      </FormContainer>
    </Wrapper>
  );
}

export default StatisticPage;
