import { ROOT_URL } from "config";

const getAgencies = async () => {
  try {
    const response = await fetch(ROOT_URL + `/agency`, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getAgencyCode = async (data) => {
  try {
    const body = {};
    Object.keys({ ...data }).forEach((key) => {
      body[key] = data[key].trim();
    })
    const response = await fetch(ROOT_URL + `/agency/user/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const error = await response.json();
      console.log({ error })
      throw new Error(error);
    } else {
      return await response.json();
    }
  } catch (Exception) {
    throw new Error(Exception);
  }
};

const getAgencyPrograms = async ({ id }) => {
  try {
    const response = await fetch(
      ROOT_URL +
      `/program?` +
      new URLSearchParams({
        agency_id: id,
      }),
      {
        method: "GET",
      },
    );

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

export { getAgencies, getAgencyCode, getAgencyPrograms };
