import React, { useState } from "react";
import { Button, Typography } from "antd";
import styled from "styled-components";
import Swal from "sweetalert2";

import { ROOT_URL } from "config";

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100vh;
  justify-content: center;
  overflow: scroll;
  padding: 24px;
  text-align: center;
`;
const StyledImage = styled("img")`
  max-width: 550px;
  object-fit: scale-down;
  width: 100%;

  @media (max-height: 860px) {
    height: 100%;
  }
`;
const StyledButton = styled(Button)`
  span {
    color: #ffff;
  }
`;

const generateIdUrl = ({ name, designation, agency, photoUrl, token }) => {
  const queryParams = new URLSearchParams();
  if (name) queryParams.append("name", name);
  if (designation) queryParams.append("designation", designation);
  if (agency) queryParams.append("agency", agency);
  if (photoUrl) queryParams.append("photo_url", photoUrl);
  if (token) queryParams.append("token", token);

  return `${ROOT_URL}/agency_summit/id_card?${queryParams.toString()}`;
};

const fetchImageBlob = async url => {
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error("Error downloading the image.");
  }

  return await response.blob();
};

const downloadImage = (blob, filename) => {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const SummitIdGen = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const name = params.get("name") || "";
  const designation = params.get("designation") || "";
  const agency = params.get("agency") || "";
  const photoUrl = params.get("photo_url") || "";
  const token = params.get("token") || "";

  const idUrl = generateIdUrl({ name, designation, agency, photoUrl, token });

  const onClickDownload = async () => {
    try {
      setIsDownloading(true);
      const blob = await fetchImageBlob(idUrl);

      downloadImage(blob, `${name}-summit-id.jpg`);
    } catch (error) {
      Swal.fire({
        text: "Error downloading the image.",
        allowOutsideClick: true,
      });
      console.error("Error downloading the image:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Container>
      {token ? <StyledImage src={idUrl} /> : <Typography.Title level={2}>Unable to process ID card</Typography.Title>}
      <StyledButton disabled={!token} loading={isDownloading} onClick={onClickDownload} size="large" type="primary">
        Download ID
      </StyledButton>
    </Container>
  );
};

export default SummitIdGen;
