import { useEffect, useReducer } from "react";
import AuthContext from "./Context";
import React from "react";

const initialState = {
  isAuthenticated: !!localStorage.getItem("access") && !!localStorage.getItem("refresh"),
  user: null,
  tokens: {
    access: localStorage.getItem("access") || null,
    refresh: localStorage.getItem("refresh") || null,
  },
};

const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        isAuthenticated: true,
        user: action.user,
        tokens: action.tokens,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        tokens: { refresh: null, access: null },
      };
    case "UPDATE_TOKENS":
      return {
        ...state,
        tokens: action.tokens,
      };
    default:
      return state;
  }
};

// Modify the function signature to accept the children prop
const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
  };

  useEffect(() => {
    const access = localStorage.getItem("access");
    const refresh = localStorage.getItem("refresh");
    if (access && refresh) {
      dispatch({
        type: "LOGIN",
        user: null,
        tokens: {
          access,
          refresh,
        },
      });
    }
  }, []);

  const payload = {
    isAuthenticated: state.isAuthenticated,
    user: state.user,
    tokens: state.tokens,
    dispatch,
    logout,
  };

  // Ensure that the children prop is rendered correctly
  return <AuthContext.Provider value={payload}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
