/* eslint-disable no-case-declarations */
import { createContext, useContext } from "react";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: {
    user: null,
    agency: null,
    code: null,
    id: null,
    overrides: null,
  },
  login: () => null,
  logout: () => null,
  verify: () => null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALIZE":
      return { ...state, isInitialized: true };

    case "LOGIN":
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, isInitialized: true, user };

    case "LOGOUT":
      return { ...initialState, isInitialized: true };

    default:
      return state;
  }
};

const QrScannerContext = createContext(initialState);
const useQrScannerContext = () => useContext(QrScannerContext);

export { initialState, reducer, useQrScannerContext };
export default QrScannerContext;
