import React, { useState, useContext } from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Upload, Spin } from "antd";
import { CitizenEditContext } from "../CitizenEditContext";
import { EditUploadStepContainer, ImageAvatar, ReuploadBorder } from "pages/Edit/components/Form.styled";
import { uploadCitizenPhoto } from "../../../../services/public_registration.service";

const CitizenEditUpload = () => {
  const { state, stateDispatchMain } = useContext(CitizenEditContext);

  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validImageFormats = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

  const uploadMedia = async ({ file, onError }) => {
    setIsLoading(true);
    if (!validImageFormats.includes(file?.type)) {
      setTimeout(() => {
        onError("error");
      }, 100);
      return;
    }

    const uploadedMedia = await uploadCitizenPhoto(file);

    stateDispatchMain({
      key: "citizenDetails",
      payload: {
        ...state.citizenDetails,
        profile: uploadedMedia.url,
      },
    });
    setIsLoading(false);
    setImage(URL.createObjectURL(file));
  };

  const props = {
    accept: "image/*",
    name: name,
    customRequest: uploadMedia,
  };

  return (
    <EditUploadStepContainer>
      <h3>Image</h3>
      <div className="avatar-container">
        <div className="avatar">
          {isLoading ? <Spin /> : <ImageAvatar src={image || state.citizenDetails.profile} />}
        </div>
        <p>{`${state.citizenDetails.last_name}, ${state.citizenDetails.first_name} ${state.citizenDetails.name_extension}`}</p>
      </div>
      <h3>Reupload</h3>
      <div className="upload-container">
        <Upload {...props} showUploadList={false}>
          <ReuploadBorder
            type="dashed"
            icon={<CloudUploadOutlined style={{ fontSize: "30px", fontWeight: "bold", marginBottom: "10px" }} />}>
            <span>I-open ang camera o ang gallery.</span>
            <span className="upload-click">Click here.</span>
          </ReuploadBorder>
        </Upload>
      </div>
    </EditUploadStepContainer>
  );
};

export default CitizenEditUpload;
