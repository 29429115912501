import WizardForm from "components/form/wizard";
import React, { useContext } from "react";
import { LayoutContainer, LayoutLogoContainer, Logo } from "../components/Layout.styled";
import VerificationInformation from "./steps/VerificationInformation";
import VerificationOTP from "./steps/VerificationOTP";
import logo from "../../../assets/bagong_pilipinas_logo.png";
import { CitizenEditContext } from "./CitizenEditContext";
import CitizenEditPersonalInformation from "./steps/CitizenEditPersonalInformation";
import CitizenEditAddress from "./steps/CitizenEditAddress";
import CitizenEditUpload from "./steps/CitizenEditUpload";
import CitizenEditConfirm from "./steps/CitizenEditConfirm";
import CitizenSuccess from "./steps/CitizenSuccess";

const CitizenEditComponent = () => {
  const { checkUserExist, verifyOTP, checkAgreement } = useContext(CitizenEditContext);
  const steps = [
    {
      title: "Verify it's you",
      component: VerificationInformation,
      hideBackBtn: true,
      asyncValidator: checkUserExist,
    },
    {
      title: "OTP Verification",
      component: VerificationOTP,
      hideBackBtn: false,
      hideTitle: true,
      centerTitle: true,
      btnNextOverwriteTitle: "Ipadala",
      asyncValidator: verifyOTP,
    },
    {
      title: "Personal Information",
      component: CitizenEditPersonalInformation,
      hideBackBtn: true,
    },
    {
      title: "Address",
      component: CitizenEditAddress,
    },
    {
      title: "Upload Image",
      component: CitizenEditUpload,
      hideBackBtn: false,
      hideTitle: true,
      centerTitle: true,
      btnNextOverwriteTitle: "Ipadala",
    },
    {
      title: "Confirm",
      component: CitizenEditConfirm,
      hideTitle: true,
      centerTitle: true,
      btnNextOverwriteTitle: "Ipadala",
      asyncValidator: checkAgreement,
    },
    {
      title: "",
      component: CitizenSuccess,
      hideTitle: true,
      centerTitle: true,
      btnNextOverwriteTitle: "Ipadala",
      hideBackBtn: true,
      hideWizardSteps: true,
      hideNextBtn: true,
    },
  ];

  return (
    <LayoutContainer>
      <LayoutLogoContainer>
        <Logo src={logo} alt="Bagong Pilipinas logo" />
      </LayoutLogoContainer>
      <WizardForm steps={steps} />
    </LayoutContainer>
  );
};

export default CitizenEditComponent;
