import React from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { useAuth } from "./Context";

const RequireAuth = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const redirectParam = searchParams.get("redirect");

  // If coming from login and already authenticated, redirect to home or specified path
  if (location.pathname === "/login" && isAuthenticated) {
    return <Navigate to={redirectParam || "/"} replace />;
  }

  // If not authenticated, redirect to login with a redirect back to the intended page
  if (!isAuthenticated) {
    return <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname)}`} replace />;
  }

  return children;
};

export default RequireAuth;
