import React, { useRef } from "react";
import { Alert, Button, Input, message } from "antd";
import { CheckCircleTwoTone, WarningOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useZxing } from "react-zxing";
import { useQrScannerContext } from "../Context";
import Title from "antd/lib/typography/Title";
import styled from "styled-components";
import { StyledElement as BackgroundContainer } from "pages/SocialService/components/reusables";
import { redeemSocialService } from "services/citizen";

const Container = styled(BackgroundContainer)`
  height: 100vh;
  position: absolute;
  z-index: 1;
  height: 100vh;
  width: 100%;
`;
const QrScanner = styled.video`
  width: 100%;
  height: calc(100% - 165px);
  object-fit: cover;
  position: absolute;
  top: 165px;
  z-index: -1;
`;

const LogoutButton = styled(Button)`
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 12px;
`;

const Header = styled(Title)`
  color: white !important;
  margin: 0 !important;
`;

const TopOverlay = styled.div`
  position: absolute;
  background-color: rgba(41, 51, 146, 0.68);
  display: flex;

  align-items: center;
  justify-content: center;

  height: 100px;
  width: 100vw;
`;

const BottomOverlay = styled.div`
  position: absolute;
  bottom: 0;
  background-color: rgba(41, 51, 146, 0.68);
  display: flex;
  flex-direction: column;

  height: 180px;
  padding-top: 25px;
  padding-left: 10%;
  padding-right: 10%;
  width: 100vw;
`;

const LeftOverlay = styled.div`
  position: absolute;
  background-color: rgba(41, 51, 146, 0.68);
  display: flex;
  top: 265px;

  left: 0;

  align-items: center;
  justify-content: center;

  justify-content: center;
  height: calc(100% - 195px - 250px);
  width: 10%;
`;

const RightOverlay = styled.div`
  position: absolute;
  background-color: rgba(41, 51, 146, 0.68);
  top: 265px;

  right: 0;

  align-items: center;
  justify-content: center;

  height: calc(100% - 195px - 250px);
  width: 10%;
`;

const SearchButton = styled(Button)`
  z-index: 2;
  margin-top: 15px;
  border-radius: 10px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #dd4542;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.07);
  span {
    color: #fff;
  }
`;

const IdSearchInput = styled(Input)`
  margin-top: 10px;
`;

const Overlay = () => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  return (
    <div style={{ zIndex: 5 }}>
      <TopOverlay>
        <Header level={3}>Scan QR Code</Header>
      </TopOverlay>
      <LeftOverlay />
      <RightOverlay />
      <BottomOverlay>
        <Header level={4}>Couldn&apos;t find Citizen?</Header>
        <Header level={5}>Enter ID number instead.</Header>
        <IdSearchInput ref={inputRef} placeholder="Enter ID Number" />
        <SearchButton
          onClick={() => {
            navigate(`/citizen/${inputRef.current.input.value.trim()}`);
          }}>
          Search Citizen
        </SearchButton>
      </BottomOverlay>
    </div>
  );
};

const VideoQrScanner = () => {
  const { logout, user } = useQrScannerContext();
  const [error, setError] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [lastScannedQRs, setLastScannedQRs] = useState([]);

  const onRedeem = result => {
    if (user.overrides) {
      messageApi.loading({
        key: "quickRedeem",
        content: "Redeeming....",
        className: "quickRedeem",
        style: {
          fontWeight: 500,
        },
      });

      redeemSocialService({
        citizen: result.getText(),
        agency_user: user?.id || null,
        agency: user?.agency.id || null,
        ...user.overrides["citizen_program"],
      })
        .then(() => {
          messageApi.success({
            key: "quickRedeem",
            content: "Successfully redeemed",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
            style: {
              fontWeight: 500,
            },
          });
        })
        .catch(() => {
          messageApi.error({
            key: "quickRedeem",
            content: "Error redeeming",
            style: {
              fontWeight: 500,
            },
          });
        });
    } else {
      navigate(`/citizen/${result.getText()}`);
    }
  };

  const { ref } = useZxing({
    onError: error => {
      setError({
        message: "Error",
        description: error.message,
      });
    },
    paused: !!error,
    timeBetweenDecodingAttempts: 2000,
    constraints: {
      video: {
        facingMode: "environment",
      },
    },
    onDecodeResult(result) {
      if (result) {
        if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(result.getText())) {
          setLastScannedQRs([...lastScannedQRs, result.getText()]);

          if (lastScannedQRs.includes(result.getText())) {
            messageApi.warning({
              key: "duplicate",
              content: "Duplicate QR Code",
              style: {
                fontWeight: 500,
              },
              icon: <WarningOutlined fill="#ffcc00" />,
            });
          } else {
            onRedeem(result);
          }
        } else {
          setError({
            message: "Error",
            description: "Invalid QR Code",
          });
        }
      }
    },
  });

  return (
    <Container>
      {contextHolder}
      <Overlay />
      <QrScanner ref={ref} />
      {error ? (
        <Alert
          message={error.message}
          description={error.description}
          type="error"
          closable
          onClose={() => setError(null)}
        />
      ) : null}
      <LogoutButton
        onClick={() => {
          logout();
        }}
        size="small">
        Logout
      </LogoutButton>
    </Container>
  );
};
export default VideoQrScanner;
