import React, { useContext, useEffect } from "react";
import {
  FormContainer,
  FormItem,
  InputTextFields,
  InputPhoneNumberField,
  InputSelectField,
  InputDateSelectField,
  BirthdateSelectContainer,
} from "pages/Edit/components/Form.styled";
import { CitizenEditContext } from "../CitizenEditContext";
import { daysOptions, monthsOptions, yearOptions } from "../../../../constant/selectConstants";

const VerificationInformation = ({ form, formData }) => {
  const { first_name, last_name, suffix } = formData;

  const {
    state,
    stateDispatchMain,
    formatPhoneNumber,
    parsePhoneNumber,
    provinceOptions,
    municipalityOptions,
    barangayOption,
    setSelectedProvince,
    setSelectedMunicipality,
    selectedMunicipality,
    selectedProvince,
    isLoading,
  } = useContext(CitizenEditContext);

  useEffect(() => {
    const year = form?.getFieldValue("year");
    const month = form?.getFieldValue("month");
    const date = form?.getFieldValue("date");

    if (date) {
      const dateOptions = daysOptions(month, year);

      if (!dateOptions.find(d => d.value === date)) {
        form?.setFieldsValue({ date: undefined });
      }
    }
  }, [form?.getFieldValue("month"), form?.getFieldValue("year")]);

  return (
    <FormContainer>
      <FormItem label="Pangalan" name="first_name" rules={[{ required: true, message: "First name is required" }]}>
        <InputTextFields
          name="first_name"
          value={first_name}
          onChange={e =>
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                first_name: e.target.value,
              },
            })
          }
        />
      </FormItem>
      <FormItem label="Apelyido" name="last_name" rules={[{ required: true, message: "Last name is required" }]}>
        <InputTextFields
          name="last_name"
          value={last_name}
          onChange={e =>
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                last_name: e.target.value,
              },
            })
          }
        />
      </FormItem>
      <FormItem label="Ext (Sr,Jr,I,II,III)" name="suffix">
        <InputTextFields
          name="suffix"
          value={suffix}
          onChange={e =>
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                suffix: e.target.value,
              },
            })
          }
        />
      </FormItem>

      <FormItem label="Birthdate">
        <BirthdateSelectContainer>
          <FormItem
            className="personal-info-date-select"
            label="Year"
            name="year"
            rules={[{ required: true, message: "Year is required." }]}>
            <InputDateSelectField
              options={yearOptions()}
              showSearch
              allowClear
              onChange={value => {
                stateDispatchMain({
                  key: "citizenDetails",
                  payload: {
                    ...state.citizenDetails,
                    birthdate_year: value,
                  },
                });
              }}
            />
          </FormItem>
          <FormItem
            className="personal-info-date-select"
            label="Month"
            name="month"
            rules={[{ required: true, message: "Month is required." }]}>
            <InputDateSelectField
              options={monthsOptions()}
              filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
              showSearch
              allowClear
              onChange={value => {
                stateDispatchMain({
                  key: "citizenDetails",
                  payload: {
                    ...state.citizenDetails,
                    birthdate_month: value,
                  },
                });
              }}
            />
          </FormItem>
          <FormItem
            className="personal-info-date-select"
            label="Date"
            name="date"
            rules={[{ required: true, message: "Date is required." }]}>
            <InputDateSelectField
              options={daysOptions(form?.getFieldValue("month"), form?.getFieldValue("year"))}
              disabled={!form?.getFieldValue("year") || !form?.getFieldValue("month")}
              showSearch
              allowClear
              onChange={value => {
                stateDispatchMain({
                  key: "citizenDetails",
                  payload: {
                    ...state.citizenDetails,
                    birthdate_day: value,
                  },
                });
              }}
            />
          </FormItem>
        </BirthdateSelectContainer>
      </FormItem>
      <FormItem label="Probinsya" name="province" rules={[{ required: true, message: "Probinsya is required" }]}>
        <InputSelectField
          name="province"
          bordered={false}
          onChange={options => {
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                province: options,
              },
            });
            setSelectedProvince(options);
          }}
          options={provinceOptions}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        />
      </FormItem>
      <FormItem
        label="Lungsod/Munisipyo"
        name="municipality"
        rules={[{ required: true, message: "Lungsod/Munisipyo is required" }]}>
        <InputSelectField
          key="municipality"
          name="municipality"
          bordered={false}
          onChange={(_, option) => {
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                municipality: option?.value,
              },
            });
            setSelectedMunicipality(option?.value);
            form.setFieldsValue({ municipality: option });
          }}
          disabled={!selectedProvince}
          options={municipalityOptions}
          value={selectedMunicipality || null}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        />
      </FormItem>
      <FormItem label="Barangay" name="barangay" rules={[{ required: true, message: "Lungsod/Munisipyo is required" }]}>
        <InputSelectField
          name="barangay"
          allowClear
          bordered={false}
          onChange={(_, option) => {
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                barangay: option?.value,
              },
            });
            form.setFieldsValue({ barangay: option });
          }}
          options={barangayOption}
          disabled={!selectedMunicipality}
          showSearch
          notFoundContent={!isLoading ? "Loading.." : "No Data"}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        />
      </FormItem>
      <FormItem
        label="Mobile Number"
        name="phone_number"
        validateTrigger={["onBlur"]}
        rules={[
          { required: true, message: "Mobile number is required" },
          {
            validator: async (_, value) => {
              if (value.toString().length < 10) {
                return Promise.reject("Incorrect mobile number format");
              } else {
                return Promise.resolve();
              }
            },
            message: "Incorrect mobile number format",
          },
        ]}>
        <InputPhoneNumberField
          name="phone_number"
          autoComplete="off"
          formatter={formatPhoneNumber}
          parser={parsePhoneNumber}
          onChange={value => {
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                phone_number: value,
              },
            });
          }}
          onKeyPress={event => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          defaultValue={63}
          maxLength={12}
          minLength={12}
        />
      </FormItem>
    </FormContainer>
  );
};

export default VerificationInformation;
