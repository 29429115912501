import { Avatar, Button, Modal, Spin, Table, message } from "antd";
import React, { useContext, useRef, useState } from "react";
import fallbackIdPrevImg from "assets/no_img_found.png";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
`;

function StatisticTable({ data, loading, pagination, columns, resendInterval }) {
  /**
   * @type {React.MutableRefObject<import("antd").FormInstance>}
   */

  return (
    <Wrapper>
      <Table loading={loading} columns={columns} dataSource={data} pagination={pagination} id="card_number">
        IDTable
      </Table>
    </Wrapper>
  );
}

export default StatisticTable;
