import Compressor from 'compressorjs';

const maxImageByteSize = 1500000; // 1.5MB

export const getFileExtensionFromType = (fileType = '') => {
  const parts = fileType.split('/');
  const fileExtension = parts[1];

  return fileExtension;
};

export const compressImage = (file, quality = 0.7) =>
  new Promise((resolve, reject) => {
    if (file.size > maxImageByteSize) {
      quality = 1 / (file.size / maxImageByteSize);
      if (quality < 0.2) {
        quality = 0.2;
      }
    }

    new Compressor(file, {
      quality, // 0.6 can also be used, but its not recommended to go below.
      success: async (compressedBlob) => {
        const fileName =
          file?.name || `photo.${getFileExtensionFromType(file.type)}`;

        const imageFile = new File(
          [new Blob([compressedBlob], { type: file.type })],
          fileName,
          { type: file.type }
        );
        resolve(imageFile);
      },
      error: (err) => reject(err),
    });
  });

export const containsSpecialFontCharacters = (str = '') => {
  const specialFontRegex = /[\u2100-\u27BF\uE000-\uF8FF,/#!$%^&*;:{}=_`~()]/u;


  return specialFontRegex.test(str);
}