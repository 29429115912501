import React from "react";
import { useNavigate } from "react-router-dom";
import { RegisterHomePageContainer } from "../components/Register.styled";
import BP_LOGO from "../../../assets/bagong_pilipinas_logo.png";
import BG from "../../../assets/bg.png";
import GRADIENT from "../../../assets/corner_gradient.png";
import MOBILE_BG from "../../../assets/mobile_bg.png";
import MOBILE_GRADIENT from "../../../assets/mobile_gradient.png";
import ARROW from "../../../assets/register_arrow.png";
import { Button } from "antd";

const RegisterHomepage = () => {
  const navigate = useNavigate();

  return (
    <RegisterHomePageContainer>
      <div className="left-side-container">
        <img src={BP_LOGO} className="bp-logo" />
        <img src={BG} className="bg" />
      </div>

      <div className="right-side-container">
        <div className="text-container">
          <h2>
            Mag-rehistro na para sa <br />
            <strong>Bagong Pilipinas</strong>
          </h2>
        </div>
        <div className="button-container">
          <Button className="mobile-register-btn" onClick={() => navigate("/agreement")}>
            Register
          </Button>
          <Button className="register-btn" onClick={() => navigate("/agreement")}>
            Register
            <img src={ARROW} className="register-btn-arrow" />
          </Button>
        </div>
        <img src={GRADIENT} className="gradient" />
      </div>

      <img src={MOBILE_GRADIENT} className="mobile-gradient" />
      <img src={MOBILE_BG} className="mobile-bg" />
    </RegisterHomePageContainer>
  );
};

export default RegisterHomepage;
