const { ROOT_URL } = require("config");

async function fetchWithTokenRefresh(url, options = {}) {
  // Inject the Authorization header into all requests
  const accessToken = localStorage.getItem("access");
  if (accessToken) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  // Perform the fetch call
  let response = await fetch(url, options);

  // If the response is unauthorized, try to refresh the token
  if (response.status === 401) {
    const didRefresh = await refreshToken();
    if (didRefresh) {
      // Update accessToken after refresh
      const newAccessToken = localStorage.getItem("access");
      options.headers.Authorization = `Bearer ${newAccessToken}`;

      // Retry the fetch call with the new token
      response = await fetch(url, options);
    } else {
      // Handle failure to refresh token, e.g., redirect to login
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      window.location.reload();
      console.error("Failed to refresh token.");
      // Redirect to login or perform other error handling
    }
  }

  return response;
}

const getCitizen = async id => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/${id}`, {
      method: "GET",
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error);
    } else {
      return await response.json();
    }
  } catch (Exception) {
    console.error(Exception.toString());
  }
};

const getCitizenByCardNumber = async (id = "") => {
  try {
    const url = `${ROOT_URL}/citizen/card_number/${id.trim()}`;

    const response = await fetchWithTokenRefresh(url, {
      method: "GET",
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error);
    } else {
      return await response.json();
    }
  } catch (Exception) {
    console.error(Exception);
  }
};

const getCitizensList = async (search, birth_date, page, page_size) => {
  const params = new URLSearchParams({
    ...(search ? { search } : {}),
    ...(birth_date ? { birth_date } : {}),
    page,
    page_size,
  });

  try {
    const response = await fetchWithTokenRefresh(ROOT_URL + `/citizen/?${params}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    console.log(response);
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error);
    } else {
      return await response.json();
    }
  } catch (Exception) {
    console.error(Exception);
  }
};

const getHistory = async id => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/redeem/history/${id}/`, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const redeemSocialService = async data => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/redeem/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getProgramTypes = async () => {
  try {
    const response = await fetch(ROOT_URL + `/program/type/`, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const searchLoginService = async (username, password) => {
  let formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  try {
    const response = await fetch(ROOT_URL + "/auth/login/", {
      method: "POST",
      body: formData,
    });
    if (!response.ok) {
      const error = await response.json();
      console.log({ error });
      throw new Error(error);
    } else {
      return await response.json();
    }
  } catch (Exception) {
    throw new Error(Exception);
  }
};

const refreshToken = async () => {
  console.log("Attempting token refresh");
  try {
    // Logic to refresh the token
    // For example, using a refreshToken to get a new accessToken
    let formData = new FormData();
    formData.append("refresh", localStorage.getItem("refresh"));
    const refreshResponse = await fetch(ROOT_URL + "/auth/refresh/", {
      method: "POST",
      body: formData,
    });

    if (!refreshResponse.ok) {
      throw new Error("Could not refresh the token.");
    }

    const data = await refreshResponse.json();
    localStorage.setItem("access", data.access);
    localStorage.setItem("refresh", data.refresh);
    return true;
  } catch (error) {
    console.error("Error refreshing token:", error);
    return false;
  }
};

const getCitizenDetails = async (first_name, last_name, suffix, birth_date) => {
  try {
    const response = await fetchWithTokenRefresh(
      `${ROOT_URL}/citizen/?first_name=${encodeURIComponent(first_name)}&last_name=${encodeURIComponent(
        last_name,
      )}&name_extension=${encodeURIComponent(suffix)}&birth_date=${encodeURIComponent(birth_date)}`,
      {
        method: "GET",
      },
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error);
    } else {
      return await response.json();
    }
  } catch (Exception) {
    console.error(Exception);
  }
};

const editCitizenDetails = async (id, citizenDetails) => {
  try {
    const response = await fetchWithTokenRefresh(`${ROOT_URL}/citizen/${id}/`, {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
      method: "PATCH",
      body: JSON.stringify(citizenDetails),
    });

    if (!response.ok) {
      // Assuming you want to handle HTTP errors the same way
      const error = await response.json();
      throw new Error(error.error);
    }

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

export {
  getCitizen,
  getHistory,
  redeemSocialService,
  getCitizenByCardNumber,
  getCitizensList,
  getProgramTypes,
  searchLoginService,
  getCitizenDetails,
  editCitizenDetails,
};
