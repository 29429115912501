import React from "react";
import QrScanner from "./components/QrScanner";

const QrScanPage = () => {
  return (
    <QrScanner />
  );
};

export default QrScanPage;
