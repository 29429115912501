import { ConfirmContainer } from "pages/Edit/components/Form.styled";
import React, { useContext } from "react";
import { Button as AntdButton } from "antd";
import { CitizenEditContext } from "../CitizenEditContext";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Button = styled(AntdButton)`
border-radius: 8px;
width: 100%;
margin: 5px 0px;

&.ant-btn-primary { 
    background-color: #AC141E;
    color: #FFFFFF; 
    border-color: #AC141E;

    span {
        color: #FFFFFF;
    }

    &:hover, &:focus {
      background-color: #9a121b;
      border-color: #9a121b;
    }

}

&.ant-btn-default {
    background-color: #BCBCBC;
    border-color: #BCBCBC;

    span {
        color: #000000;
    }

    &:hover, &:focus {
        background-color: #EBEBEB;
        border-color: #EBEBEB;
    }
}

&.ant-btn-social-service {
    background-color: #FF6E03;
    border-color: #FF6E03;


    span {
        color: #FFFFFF;
        font-weight: bold;
    }

    &:hover, &:focus {
        background-color: #FF7916
        border-color: #FF7916;
    }
}

@media only screen and (min-width: 768px) {
    border-radius: 3px;
    width: 40%;
    margin: 5px 30px;
}


`;

const CitizenEditConfirm = () => {
  const navigate = useNavigate();
  return (
    <ConfirmContainer>
      <h3 className="header-title">Successfully Edited!</h3>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button type="primary" onClick={() => navigate("/")}>
          Home
        </Button>
      </div>
    </ConfirmContainer>
  );
};

export default CitizenEditConfirm;
