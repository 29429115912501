import React, { useCallback, useEffect, useReducer, useRef } from "react";
import Context, { initialState, reducer } from "./Context";

const userLocalKey = "qrUser";
const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isVerified = useRef(false);

  const login = useCallback(user => {
    localStorage.setItem(userLocalKey, JSON.stringify(user));
    dispatch({ type: "LOGIN", payload: { user } });

    return user;
  }, []);

  const logout = useCallback(() => {
    try {
      localStorage.removeItem(userLocalKey);
      dispatch({ type: "LOGOUT" });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const verify = useCallback(async () => {
    try {
      const userData = JSON.parse(localStorage.getItem(userLocalKey));

      if (!userData) {
        throw new Error("Unauthenticated!");
      }

      login(userData);
    } catch (error) {
      dispatch({ type: "INITIALIZE" });
      console.warn(error);
      localStorage.removeItem(userLocalKey);
    }
  }, []);

  useEffect(() => {
    if (!isVerified.current) {
      verify();
      isVerified.current = true;
    }
  }, []);

  return <Context.Provider value={{ ...state, login, logout, verify }}>{children}</Context.Provider>;
};

export default Provider;
