import { Avatar, Col, Divider, Row } from "antd";
import React from "react";
import { AntDesignOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import { styled } from "styled-components";
import { Spin } from "antd";
import { format, parseISO } from "date-fns";

const StyledTitle = styled(Title)`
  margin-bottom: 0 !important;
  @media only screen and (min-width: 700px) {
    color: white !important;
  }
`;
const StyledId = styled(Title)`
  margin-top: 10px;
  margin-bottom: 0 !important;
  text-align: center;
  @media only screen and (min-width: 700px) {
    color: white !important;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 12px 0px !important;

  @media only screen and (min-width: 700px) {
    border-top: 1px solid white !important;
  }
`;

const StyledSubTitle = styled.span`
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.257px;
  @media only screen and (min-width: 700px) {
    color: white !important;
  }
`;

const AddressTypography = styled.span`
  color: #000;
  padding-top: 10px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.257px;
  @media only screen and (min-width: 700px) {
    color: white !important;
  }
`;

const topColor = "#1E50A3";
const bottomColor = "#DE242A";

const CustomAvatar = styled(Avatar)`
  &&& {
    border: 3px solid transparent;
    background-image: linear-gradient(to bottom, ${topColor}, ${bottomColor});
  }
`;

const CitizenProfile = citizen => {
  const { profile, card_number, first_name, last_name, birth_date, phone_number, address, barangay, municipality } = citizen;
  const address1 = `${address}, ${barangay?.name || ''}`
  const address2 = `${municipality?.name || ''}, ${municipality?.province?.name || ''}`

  if (!citizen || !citizen.id) {
    return <Spin />;
  }

  return (
    <Row gutter={15}>
      <Col span={8}>
        <CustomAvatar size={100} icon={<AntDesignOutlined />} src={profile} />
        <StyledId bold level={5}>
          ID: {card_number}
        </StyledId>
      </Col>
      <Col span={16}>
        <Row>
          <StyledTitle bold level={5}>
            {last_name},
          </StyledTitle>
        </Row>
        <Row>
          <StyledTitle bold level={5}>
            {first_name}
          </StyledTitle>
        </Row>
        <StyledDivider />
        <Row gutter={5}>
          <Col span={12}>
            <StyledSubTitle level={5}>
              {birth_date ? format(parseISO(birth_date), "MMMM dd, yyyy") : null}
            </StyledSubTitle>
          </Col>
          <Col span={12}>
            <StyledSubTitle level={5}>{phone_number}</StyledSubTitle>
          </Col>
        </Row>
        <Row>
          <AddressTypography>{address1}</AddressTypography>
          <AddressTypography>{address2}</AddressTypography>
        </Row>
      </Col>
      <Divider />
    </Row>
  );
};

export default CitizenProfile;
