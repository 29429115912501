import styled from "styled-components";
import { Form, Input, InputNumber, Select, DatePicker, Button } from "antd";

const FormContainer = styled.div`
  overflow-y: scroll;
  margin-top: 10px;
  padding-bottom: 1.5rem;
  height: calc(100vh - 10rem - 3rem - 145px - 10px);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;

  @media only screen and (min-width: 768px) {
    height: fit-content;
    width: 100%;
    padding: 0;
    overflow-y: auto;
  }
`;

const FormItem = styled(Form.Item)`
  margin-top: -25px;
  width: 100%;

  input {
    height: 4rem;
    padding: 0 15px;
  }

  .ant-form-item-label {
    top: 25px;
    padding-left: 13px;
    font-size: 14px;
    z-index: 1;
  }

  .ant-form-item-label > label {
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    background-color: white;
    padding: 0px 10px;
  }

  @media only screen and (min-width: 766px) {
    display: block;

    .ant-form-item-label {
      top: 17.5px;
      font-size: 14px;
      z-index: 1;
    }

    .ant-form-item-label > label {
      position: relative;
      display: inline-flex;
      align-items: center;
      max-width: 100%;
      height: 32px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
    }
  }
`;

const InputTextFields = styled(Input)`
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;

  .ant-input {
    position: relative;
    padding: 8px 12px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;

    &:hover {
      border-color: #1890ff;
    }

    &:focus {
      border-color: #1890ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    &.ant-input-disabled {
      background-color: #f5f5f5;
      color: rgba(0, 0, 0, 0.65);
      cursor: not-allowed;
      border-color: #d9d9d9;
    }
  }
`;

const InputPhoneNumberField = styled(InputNumber)`
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
`;

const InputNumberField = styled(InputNumber)`
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
`;

const InputSelectField = styled(Select)`
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
  padding: 10px 0px;

  .ant-select-selector {
    border: none !important;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border: none !important;
  }
`;

const InputDateSelectField = styled(Select)`
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
  padding: 10px 0px;

  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    z-index: 1;
  }
`;

const InputDateField = styled(DatePicker)`
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
  padding: 10px;
`;

const BirthdateSelectContainer = styled.div`
  display: flex;
  gap: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
  padding: 10px;

  .ant-row {
    margin-top: -1.5rem;
  }

  @media only screen and (min-width: 768px) {
    width: 100%;
    justify-content: space-between;

    .personal-info-date-select {
      margin-top: 0;
    }
  }
`;

const OTPContainer = styled.div`
  overflow-y: scroll;
  margin-top: 10px;
  padding-bottom: 1.5rem;
  height: calc(100vh - 10rem - 3rem - 145px - 10px);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  text-align: center;

  @media only screen and (min-width: 768px) {
    height: fit-content;
    width: 100%;
    padding: 0;
    overflow-y: auto;
  }
`;

const EditUploadStepContainer = styled.div`
  overflow-y: scroll;
  margin-top: 10px;
  padding-bottom: 1.5rem;
  height: calc(100vh - 10rem - 3rem - 145px - 10px);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;

  .avatar-container {
    text-align: center;
    font-weight: bold;
  }

  .avatar {
    text-align: center;
  }

  h3 {
    color: #2d378c;
    font-weight: bold;
  }

  .upload-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .upload-click {
    font-weight: bold;
    color: #2d378c;
  }

  @media only screen and (min-width: 768px) {
    height: fit-content;
    width: 100%;
    padding: 0;
    overflow-y: auto;
  }
`;

const ImageAvatar = styled.img`
  border-radius: 50%;
  min-height: 200px;
  max-height: 200px;
  object-fit: cover;
  min-width: 200px;
  max-width: 200px;
  border: 5px dashed #eaeaea;
`;

const ReuploadBorder = styled(Button)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  border-radius: 20px;
  width: 100%;
  height: 100%;
`;

const ConfirmContainer = styled.div`
  margin-top: 10px;
  padding-bottom: 1.5rem;
  height: calc(100vh - 10rem - 3rem - 145px - 10px);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;

  .header-title {
    color: #2d378c;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding: 0 0 3rem 0;
  }
  .checkbox-container {
    display: flex;
    justify-content: center;
    gap: 1rem;

    .agreement-text {
      margin: 0;
      color: #00000;
      width: fit-content;
    }

    .ant-form-item-control-input {
      display: flex;
      align-items: flex-start;
    }
  }
`;

export {
  FormContainer,
  FormItem,
  InputTextFields,
  InputPhoneNumberField,
  InputSelectField,
  InputDateField,
  BirthdateSelectContainer,
  InputDateSelectField,
  InputNumberField,
  OTPContainer,
  EditUploadStepContainer,
  ImageAvatar,
  ReuploadBorder,
  ConfirmContainer,
};
