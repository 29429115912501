import React, { useContext } from "react";
import logo from "../../../assets/bagong_pilipinas_logo.png";
import { RegisterContainer, StepHeader, RegisterLogoContainer, RegisterLogo } from "../components/Register.styled";
import WizardForm from "../../../components/form/wizard";
import PersonalInformation from "./steps/PersonalInformation";
import PersonalAddress from "./steps/PersonalAddress";
import OTPVerification from "./steps/OTPVerification";
// import SocialService from "./steps/SocialService";
import UploadImage from "./steps/UploadImage";
import IDCard from "./steps/IDCard";
import { NewCitizenContext } from "./NewCitizenContext";
import Summary from "./steps/Summary";

const NewCitizensComponent = () => {
  const { checkUserExist, sendOTPMobile, verifyOTP, verifyPhoto, registerCitizenDetails } = useContext(NewCitizenContext);

  const steps = [
    {
      title: "Personal Information",
      component: PersonalInformation,
      hideBackBtn: true,
      asyncValidator: checkUserExist,
    },
    {
      title: "Address",
      component: PersonalAddress,
      asyncFn: sendOTPMobile,
    },
    {
      title: "Verification",
      centerTitle: true,
      component: OTPVerification,
      hideBackBtn: true,
      btnNextOverwriteTitle: "Ipadala",
      asyncValidator: verifyOTP,
    },
    {
      title: "Upload Image",
      centerTitle: true,
      component: UploadImage,
      hideBackBtn: true,
      btnNextOverwriteTitle: "Ipadala",
      asyncValidator: verifyPhoto,
    },
    {
      title: "Summary",
      hideTitle: true,
      component: Summary,
      hideBackBtn: true,
      btnNextOverwriteTitle: "Ipadala",
      asyncValidator: registerCitizenDetails,
    },
    {
      title: "Download ID Card",
      centerTitle: true,
      component: IDCard,
      hideBackBtn: true,
      hideWizardSteps: true,
      hideNextBtn: true,
    },
  ];

  return (
    <RegisterContainer>
      <StepHeader />
      <RegisterLogoContainer>
        <RegisterLogo src={logo} alt="tingog logo" />
      </RegisterLogoContainer>
      <WizardForm steps={steps} />
    </RegisterContainer>
  );
};

export default NewCitizensComponent;
