import React, { useState } from "react";
import { Alert, Form } from "antd";
import { FormItem, InputTextFields } from "pages/Register/components/InputField.styled";
import StyledButton from "components/button/Button";
import { useQrScannerContext } from "../Context";
import styled from "styled-components";
import Background from "assets/qr_login_bg.png";
import LogoImg from "assets/bagong_pilipinas_serbisyo.png";
import { getAgencyCode } from "services/meta";

const Container = styled.div`
  border-top: 15px solid #dd4542;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom left;
  height: 100vh;
  position: absolute;
  z-index: 1;
  height: 100vh;
  width: 100%;
  .ant-form {
    padding: 22px;
  }
`;
const Logo = styled.div`
  background-image: url(${LogoImg});
  background-repeat: no-repeat;
  background-size: contain;
  width: 254px;
  height: 111px;
  margin: 30px auto;
  text-align: center;
`;

const LoginForm = () => {
  const [form] = Form.useForm();
  const { login } = useQrScannerContext();
  const [error, setError] = useState("");

  const onFinish = async values => {
    try {
      const response = await getAgencyCode(values);
      login(response);
    } catch (error) {
      setError(error?.detail || "User not found");
      console.warn("error", error);
    }
  };

  const onFinishFailed = errorInfo => {
    console.error("Failed:", errorInfo);
  };

  return (
    <Container>
      <Logo />
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        style={{
          minWidth: "90%",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <FormItem
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}>
          <InputTextFields
            name="name"
            onChange={e =>
              form.setFieldsValue(values => ({
                ...values,
                name: e.target.value,
              }))
            }
          />
        </FormItem>

        <FormItem
          wrapperCol={{
            span: 24,
          }}
          name="code"
          label="Agency Code"
          rules={[
            {
              required: true,
              message: "Agency Code is required",
            },
          ]}>
          <InputTextFields
            name="code"
            onChange={e =>
              form.setFieldsValue(values => ({
                ...values,
                code: e.target.value,
              }))
            }
          />
        </FormItem>

        {error && (
          <Form.Item>
            <Alert description={error} type="error" closable onClose={() => setError("")} />
          </Form.Item>
        )}

        <Form.Item>
          <StyledButton type="primary" htmlType="submit" block>
            Login
          </StyledButton>
        </Form.Item>
      </Form>
    </Container>
  );
};
export default LoginForm;
