import React from "react";
import { useQrScannerContext } from "../Context";
import LoginForm from "./LoginForm";
import VideoQrScanner from "./VideoQrScanner";

const QrScanner = () => {
  const { isAuthenticated } = useQrScannerContext();

  return (
    <>
      {
        isAuthenticated ?
          <VideoQrScanner />
          : <LoginForm />
      }
    </>
  )
}
export default QrScanner;
